import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
// eslint-disable-next-line import/named
import { v4 as uuid } from 'uuid';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { getAsset, getContentPage } from '@boss/contentful-client/b2b-b2c';
import type { ContentPageFields, Environment, LOCALE_CODE } from '@boss/types/b2b-b2c';
import { Presence } from '@boss/ui';
import { isArrayWithContent } from '@boss/utils';

// FIXME: This is a temporary solution to ignore the enforce-module-boundaries rule that prevents circular dependencies
// eslint-disable-next-line @nx/enforce-module-boundaries
import { defaultLocale } from '../../../config/locales.config';
import { ComponentMapper, LoginSection, SEO } from '../components';
import { PageTypes } from '../constants';
import { useProfile } from '../hooks';
import Layout from '../layout/default';
import { getRedirectDestinationSlugs, homePageConfig } from '../utils';
import { getAdditionalPageData } from '../utils/cms';
import getServerSideTranslations from '../utils/getServerSideTranslations';
import { mapNextToContentfulLocale } from '../utils/localeMapper';
import { getMappedNavigation } from '../utils/navigation';

export const getStaticProps = async ({
  preview,
  locale,
}: GetStaticPropsContext<{ slug: string; locale: LOCALE_CODE }>) => {
  if (!locale || locale === 'default' || !CHANNEL) {
    return {
      notFound: true,
    };
  }

  const contentfulLocale = mapNextToContentfulLocale(locale);

  const [pageData, loginAsset, navigation] = await Promise.all([
    getContentPage<ContentPageFields>({
      preview: !!preview,
      slug: 'home',
      locale: contentfulLocale,
      channel: CHANNEL,
      defaultLocale,
    }),
    getAsset(['displayLoginSectionImage'], CHANNEL, {
      locale: contentfulLocale,
    }),
    getMappedNavigation(contentfulLocale, !!preview),
  ]);

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  const additionalData = await getAdditionalPageData(pageData.content, contentfulLocale);

  return {
    props: {
      pageType: PageTypes.CONTENT,
      locale,
      loginAsset,
      preview: !!preview,
      pageData,
      additionalData,
      environment: process.env.CONTENTFUL_ENVIRONMENT as Environment,
      navigation,
      ...(await getServerSideTranslations(locale, ['common', 'stores', 'routes', 'forms', 'login'])),
    },
  };
};

export const Index = ({
  pageData,
  environment,
  preview,
  navigation,
  locale,
  loginAsset,
  additionalData,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { showLoginSection, redirect: homePageRedirect } = homePageConfig;
  const { push } = useRouter();
  const { isLoggedIn } = useProfile();
  const localizedSlugs = pageData.translatedSlugs;

  const redirectDestination = getRedirectDestinationSlugs(homePageRedirect?.destination);

  if (!!homePageRedirect?.auth === isLoggedIn && redirectDestination) {
    push(redirectDestination[locale]);
  }

  return (
    <SEO
      description={pageData.metaDescription}
      imageSrc={pageData.ogImage?.image?.file.url}
      localizedSlugs={localizedSlugs}
      navigation={navigation}
      noFollow={pageData.noFollow}
      noIndex={pageData.noIndex}
      title={pageData.pageTitle}
    >
      <Layout
        crumbles={[]}
        environment={environment}
        localizedSlugs={localizedSlugs}
        navigation={navigation}
        preview={preview}
      >
        <Presence id="login-section-presence" visible={showLoginSection}>
          <LoginSection bannerImage={loginAsset} visible="anonymous" />
        </Presence>

        {isArrayWithContent(pageData?.content) &&
          pageData.content.map(entry => (
            <ComponentMapper
              colors={additionalData.colors}
              entry={entry}
              key={uuid()}
              locale={locale}
              products={additionalData.products}
              theme={pageData.theme}
            />
          ))}
      </Layout>
    </SEO>
  );
};

export default Index;
